import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PredictionPopup from "../components/predictionPopup";
import TopScoreres from "../components/StatsComponents/TopScorers";
import TeamsScrollable from "../components/StatsComponents/TeamsScrollable";
import TopAssists from "../components/StatsComponents/TopAssists";
import TopRedCards from "../components/StatsComponents/TopRedCards";
import TopYellowCards from "../components/StatsComponents/TopYellowCards";
import TeamsGrid from "../components/StatsComponents/TeamsScrollable";

function Stats() {
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("scorers"); // Default tab
  const [isLoading, setIsLoading] = useState(false);

  const renderTabs = () => (
    <div className="w-full flex flex-wrap border-b-2">
      {/* Tab Buttons */}
      {[
        { id: "scorers", label: t("topScorers") },
        { id: "assists", label: t("topAssissts") },
        { id: "redCards", label: t("topredcards") },
        { id: "yellowCards", label: t("topyellowcards") },
      ].map((tab) => (
        <button
          key={tab.id}
          onClick={() => setSelectedTab(tab.id)}
          className={`flex-1 py-2 sm:py-3 text-sm sm:text-lg text-center rounded-t-lg ${
            selectedTab === tab.id ? "bg-white shadow-md" : "bg-gray-200"
          }`}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );

  const renderTabContent = () => {
    switch (selectedTab) {
      case "scorers":
        return <TopScoreres />;
      case "assists":
        return <TopAssists />;
      case "redCards":
        return <TopRedCards />;
      case "yellowCards":
        return <TopYellowCards />;
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-24 w-24 border-t- border-b-4 border-gray-500"></div>
      </div>
    );
  }

  return (
    <div className="w-screen flex flex-col items-center justify-center bg-gray-100 py-4 px-4 sm:px-6">
      {/* Horizontal scroll container */}
      <div className="w-full overflow-x-auto">
        {/* Content wrapper */}
        <div className="flex flex-col items-center justify-center  max-w-[1640px] mx-auto space-y-6">
          {/* Prediction Button */}
          <button
            className="p-2 sm:p-3 px-4 sm:px-6 rounded-lg text-xs sm:text-sm md:text-base lg:text-xl bg-[#056A4C] text-white"
            onClick={() => setIsPopupOpen(true)}
          >
            {t("predictionPage")}
          </button>

          {/* Teams Scrollable Component */}
          <div className="w-full  overflow-x-auto">
            <TeamsGrid />
          </div>

          {/* Tabs and Content */}
          <div className="w-full  bg-white shadow-md rounded-lg p-4 ">
            {renderTabs()}
            <div className="w-full mt-4 min-h-[20rem] sm:min-h-[24rem]">
              {renderTabContent()}
            </div>
          </div>
        </div>
      </div>

      {/* Prediction Popup */}
      {isPopupOpen && (
        <PredictionPopup handleClosePopup={() => setIsPopupOpen(false)} />
      )}
    </div>
  );
}

export default Stats;
