import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TeamModal from "./TeamModal";
import {
  getTeamsFromServer,
  getTeamStatsFromServer,
} from "../../API/ServerApi";

const TeamsGrid = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "Arabic";
  const [teams, setTeams] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  // Modal states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<any>(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [teamStats, setTeamStats] = useState<any>(null);
  const [arabicTeams, setArabicTeams] = useState<any[]>([]);
  const [englishTeams, setEnglishTeams] = useState<any[]>([]);

  const getSATeams = async () => {
    setLoading(true);
    try {
      const response = await getTeamsFromServer();
      if (response && response?.length) {
        const arabicTeams: any[] = response.map((team: any) => team.ar);
        const englishTeams: any[] = response.map((team: any) => team.en);

        setArabicTeams(arabicTeams);
        setEnglishTeams(englishTeams);

        setTeams(i18n.language === "Arabic" ? arabicTeams : englishTeams);
      }
    } catch (error) {
      console.error("Failed to fetch teams:", error);
    } finally {
      setLoading(false);
    }
  };

  const getMyTeamStats = async (team: any = undefined) => {
    setLoadingModal(true);
    try {
      const data = {
        team: team?.team?.id || (selectedTeam ? team?.id : undefined),
      };
      const response = await getTeamStatsFromServer(data);
      if (response) {
        setTeamStats(response);
      }
    } catch (error) {
      console.error("Failed to fetch team stats:", error);
    } finally {
      setLoadingModal(false);
    }
  };

  const openModal = (team: any) => {
    setSelectedTeam(team);
    setLoadingModal(true);
    setIsModalOpen(true);
    getMyTeamStats(team);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTeam(null);
    setTeamStats(null);
    setLoadingModal(false);
  };

  useEffect(() => {
    getSATeams();
  }, []);

  useEffect(() => {
    setTeams(i18n.language === "Arabic" ? arabicTeams : englishTeams);
  }, [i18n.language]);

  return (
    <div
      className="w-full xs:px-4 px-2 flex justify-center items-center flex-col sm:px-8 pt-10"
      dir={isArabic ? "rtl" : "ltr"}
    >
      <h1 className="text-4xl font-bold text-gray-800 mb-8">
        {t("teamsInfo")}
      </h1>
      {loading ? (
        <p className="text-center text-lg">{t("loading")}...</p>
      ) : (
        <div className="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6 w-full">
          {teams.map((item) => (
            <div className="flex justify-center items-center">
              <div
                key={item.team.id}
                className="bg-gradient-to-r w-full h-full  max-w-60  from-green-200 to-teal-400 shadow-lg rounded-lg px-1 py-2 xs:p-4 flex flex-col items-center cursor-pointer transform hover:scale-105 transition-transform duration-300"
                onClick={() => openModal(item)}
              >
                <img
                  src={item.team.logo}
                  alt={item.team.name}
                  className="xs:w-20 xs:h-20 w-16 h-16 mb-4 rounded-full border-2 border-white"
                />
                <h2 className="text-base xs:text-lg font-semibold text-gray-800">
                  {item.team.name}
                </h2>
                <p className="xs:text-sm text-xs text-gray-700">
                  {item.team.country}
                </p>
                <p className="xs:text-sm text-xs text-gray-600 mt-1">
                  {t("founded")}: {item.team.founded}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}

      {isModalOpen && (
        <TeamModal
          isLoading={loadingModal}
          isOpen={isModalOpen}
          onClose={closeModal}
          team={selectedTeam}
          stats={teamStats}
        />
      )}
    </div>
  );
};

export default TeamsGrid;
